import React, { useState } from "react";
import forgotpassword from "../../assets/img/dashboardimg/forgotpassword.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "ApiUrl";
import { useHistory } from "react-router";

const ForgotYourPassword = () => {
  const [email, setEmail] = useState()
  const [loader, setLoader] = useState(false)
  const history = useHistory();
  let toastDesign = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }
  let rolee=window?.location?.search?.split('?')[1]
  console.log('dlflasfjd', rolee)
  const sendEmail = (event) => {
    event.preventDefault();
    if (email) {
      var data = {
        email
      }
      let tok = localStorage.getItem("accessToken");
          var config = {
            method: "post",
            url: `${API_URL}/auth/${rolee}/forget-password`,
            data: data,
            // headers: {
            //   authorization: `Bearer ` + tok
            // }
          };
          axios(config)
            .then(function (response) {
              setLoader(false)
              toast.success(response?.data?.message, toastDesign);
              // history.push('/success')
            }).catch(function (error) {
              setLoader(false)
              if (error.code == 401) {
                // refreshToken()
              }
              // // console.log('error', error)
              toast.error(error?.response?.data?.message, toastDesign)
              // // console.log(error);
            });
    }
  }
  return (
    <section className="main-login main-login-inner">
      <form onSubmit={sendEmail} className="innerlogin">
        <img src="\logo.svg" alt="logo" className="logo" />
        <div className="forgot-pass-wrapper-box">
          <div className="successImg-box">
            <img src={forgotpassword} alt="successImg" />
          </div>
          <p className="inputpara">Forgot your Password?</p>
          <p className="registered-text">
            Enter your registered email to receive password reset instructions.
          </p>
          <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" required placeholder="Enter Email" className="logininput" />
        </div>
        <button type="submit" className="common-btn">Send</button>
      </form>
    </section>
  );
};

export default ForgotYourPassword;
