import { API_URL } from 'ApiUrl'
import axios from 'axios'
import Loader from 'loader/loader'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import CustomerTable from 'views/DataTable/CustomerTable'
import CustomerPurchases from 'views/DataTable/CustomerPurchases'
import DataTable from 'views/DataTable/DataTable'
import DataTableADetail from 'views/DataTable/DataTableADetail'
import "./affiliates.scss"
import { toast } from "react-toastify";
import { Table } from 'reactstrap'

const Affiliatesdetails = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    const [affliatesDetail, setAffiliateDetail] = useState()
    const [loader, setLoader] = useState(false)
    const history = useHistory()
    const affiliateId = window?.location?.search?.split('||')[1]
    const paramName = window?.location?.search?.split('||')[0]?.split('?')[1]
    // // console.log('first', window?.location?.search?.split('||')[1], window?.location?.search?.split('||')[0]?.split('?')[1])
    const getAffliates = async () => {
        let tok = localStorage.getItem("accessToken");
        try {
            setLoader(true)
            const res = await axios.get(`${API_URL}/${paramName}/${affiliateId}`, {
                headers: {
                    authorization: `Bearer ` + tok
                }
            })
            setLoader(false)
            // // console.log('first', res?.data?.data)
            setAffiliateDetail(res?.data?.data)
            // // console.log('res', res?.data?.data?.affiliates)
        } catch (error) {
            if (error.code == 401) {
                refreshToken()
            }
            setLoader(false)
            // // console.log('res error', error)
        }
    }

    useEffect(() => {
        getAffliates()
    }, [window?.location?.search?.split('?')[1]])
    const refreshToken = () => {


        let tok = localStorage.getItem("refreshToken");
        var data = ({
            refreshToken: tok,
        });
        var config = ''
        if (user?.role == 'admin') {
            config = {
                method: "post",
                url: `${API_URL}/auth/admins/refresh-token`,
                headers: {
                    authorization: `Bearer ` + tok
                },
                data: data,
            };
        } else {
            config = {
                method: "post",
                url: `${API_URL}/auth/affiliate/refresh-token`,
                headers: {
                    authorization: `Bearer ` + tok
                },
                data: data,
            };
        }

        axios(config)
            .then(function (response) {
                localStorage.setItem("accessToken", response?.data?.data?.accessToken);
                window?.location?.reload();
            })
            .catch(function (error) {
                if (user?.role == 'admin') {
                    if (error.code == 401) {
                        localStorage.clear();
                        history.push("/adminlogin");
                    }
                } else {
                    if (error.code == 401) {
                        localStorage.clear();
                        history.push("/affiliate");
                    }
                }


                toast.error(error.response.data.message);
            });
    }


    return (
        <>
            {loader && <Loader />}
            <div className="content">
                <section className="affiliatesdetails">
                    <div className="container-fluid">
                        <div className="upperheadings">
                            <button onClick={() => history.goBack()} className="back-btn">
                                <img src="\assests\Extras\arrowback.svg" alt="backarrow" className="backarrow" />
                                Back
                            </button>
                            <h4 className="affiliatesdetailheading"><span className='text-capitalize'>{paramName}</span>  Detail</h4>
                        </div>
                        {/* admin detail of users */}
                        {(user?.role === 'admin' && paramName === 'affiliates') &&
                            <div className="topupperleft">
                                <div className="affiliatescard">
                                    <div className="upperaffiliatescard">
                                        <div className="inneraffiliatescard">
                                            <div className="inneraffiliatesimg colorone">
                                                <img src="\assests\Extras\Group 1599.svg" alt="affiliatesimg" className="affiliatesimg" />
                                            </div>
                                            <p className="inneraffiliatescardtext">Total Agents</p>
                                        </div>
                                    </div>
                                    <p className="inneraffiliatescardnumber">{affliatesDetail?.agentsCount}</p>
                                </div>
                                <div className="affiliatescard">
                                    <div className="upperaffiliatescard">
                                        <div className="inneraffiliatescard">
                                            <div className="inneraffiliatesimg colortwo">
                                                <img src="\assests\Extras\moneys.svg" alt="affiliatesimg" className="affiliatesimg" />
                                            </div>
                                            <p className="inneraffiliatescardtext">Net Revenue</p>
                                        </div>
                                    </div>
                                    <p className="inneraffiliatescardnumber">${(user?.role === 'admin' && affliatesDetail?.stats[0]?.totalTomiRevenue) || (user?.role === 'affiliate' && affliatesDetail?.stats[0]?.totalAffiliateRevenue) || (user?.role === 'agent' && affliatesDetail?.stats[0]?.totalAgentRevenue) || 0}</p>
                                </div>
                            </div>
                        }
                        {(user?.role === 'admin' && paramName === 'agents') &&
                            <div className="main-customers topupperleft">
                                <div className="affiliatescard">
                                    <div className="upperaffiliatescard">
                                        <div className="inneraffiliatescard">
                                            <div className="inneraffiliatesimg colorone">
                                                <img src="\assests\customers-assets\people.svg" alt="customersimg" className="customersimg" />
                                            </div>
                                            <p className="inneraffiliatescardtext">Total Customers</p>
                                        </div>
                                    </div>
                                    <p className="inneraffiliatescardnumber">{affliatesDetail?.customersCount}</p>
                                </div>
                                <div className="affiliatescard">
                                    <div className="upperaffiliatescard">
                                        <div className="inneraffiliatescard">
                                            <div className="inneraffiliatesimg colortwo">
                                                <img src="\assests\Extras\moneys.svg" alt="affiliatesimg" className="affiliatesimg" />
                                            </div>
                                            <p className="inneraffiliatescardtext">Net Revenue</p>
                                        </div>
                                    </div>
                                    <p className="inneraffiliatescardnumber">${(user?.role === 'admin' && affliatesDetail?.stats[0]?.totalTomiRevenue) || (user?.role === 'affiliate' && affliatesDetail?.stats[0]?.totalAffiliateRevenue) || (user?.role === 'agent' && affliatesDetail?.stats[0]?.totalAgentRevenue) || 0}</p>
                                </div>
                            </div>
                        }
                        {(user?.role === 'admin' && paramName === 'customers') &&
                            <div className="main-customers">
                                <div className="topupperleft">
                                    <div className="customerscard">
                                        <div className="uppercustomerscard">
                                            <div className="innercustomerscard">
                                                <div className="innercustomersimg colorone">
                                                    <img src="\assests\customers-assets\people.svg" alt="customersimg" className="customersimg" />
                                                </div>
                                                <p className="innercustomerscardtext">Total Purchase Amount</p>
                                            </div>
                                        </div>
                                        <p className="innercustomerscardnumber">${affliatesDetail?.stats[0]?.totalPurchaseAmount || 0}</p>
                                    </div>
                                    <div className="customerscard">
                                        <div className="uppercustomerscard">
                                            <div className="innercustomerscard">
                                                <div className="innercustomersimg colortwo">
                                                    <img src="\assests\customers-assets\money-recive.svg" alt="customersimg" className="customersimg" />
                                                </div>
                                                <p className="innercustomerscardtext">Total Cash Back</p>
                                            </div>
                                        </div>
                                        <p className="innercustomerscardnumber">${affliatesDetail?.stats[0]?.totalCashback || 0}</p>
                                    </div>
                                    <div className="customerscard">
                                        <div className="uppercustomerscard">
                                            <div className="innercustomerscard">
                                                <div className="innercustomersimg colorthree">
                                                    <img src="\assests\customers-assets\moneys.svg" alt="customersimg" className="customersimg" />
                                                </div>
                                                <p className="innercustomerscardtext">Total Revenue</p>
                                            </div>
                                        </div>
                                        <p className="innercustomerscardnumber">${(user?.role === 'admin' && affliatesDetail?.stats[0]?.totalTomiRevenue) || (user?.role === 'affiliate' && affliatesDetail?.stats[0]?.totalAffiliateRevenue) || (user?.role === 'agent' && affliatesDetail?.stats[0]?.totalAgentRevenue) || 0}</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* admin detail of users */}
                        {/* affiliate detail of users */}
                        {(user?.role === 'affiliate' && paramName === 'agents') &&
                            <div className="main-customers topupperleft">
                                <div className="affiliatescard">
                                    <div className="upperaffiliatescard">
                                        <div className="inneraffiliatescard">
                                            <div className="inneraffiliatesimg colorone">
                                                <img src="\assests\customers-assets\people.svg" alt="customersimg" className="customersimg" />
                                            </div>
                                            <p className="inneraffiliatescardtext">Total Customers</p>
                                        </div>
                                    </div>
                                    <p className="inneraffiliatescardnumber">{affliatesDetail?.customersCount}</p>
                                </div>
                                <div className="affiliatescard">
                                    <div className="upperaffiliatescard">
                                        <div className="inneraffiliatescard">
                                            <div className="inneraffiliatesimg colortwo">
                                                <img src="\assests\Extras\moneys.svg" alt="affiliatesimg" className="affiliatesimg" />
                                            </div>
                                            <p className="inneraffiliatescardtext">Net Revenue</p>
                                        </div>
                                    </div>
                                    <p className="inneraffiliatescardnumber">${(user?.role === 'admin' && affliatesDetail?.stats[0]?.totalTomiRevenue) || (user?.role === 'affiliate' && affliatesDetail?.stats[0]?.totalAffiliateRevenue) || (user?.role === 'agent' && affliatesDetail?.stats[0]?.totalAgentRevenue) || 0}</p>
                                </div>
                            </div>
                        }
                        {(user?.role === 'affiliate' && paramName === 'customers') &&
                            <div className="main-customers">
                                <div className="topupperleft">
                                    <div className="customerscard">
                                        <div className="uppercustomerscard">
                                            <div className="innercustomerscard">
                                                <div className="innercustomersimg colorone">
                                                    <img src="\assests\customers-assets\people.svg" alt="customersimg" className="customersimg" />
                                                </div>
                                                <p className="innercustomerscardtext">Total Purchase Amount</p>
                                            </div>
                                        </div>
                                        <p className="innercustomerscardnumber">${affliatesDetail?.stats[0]?.totalPurchaseAmount || 0}</p>
                                    </div>
                                    <div className="customerscard">
                                        <div className="uppercustomerscard">
                                            <div className="innercustomerscard">
                                                <div className="innercustomersimg colortwo">
                                                    <img src="\assests\customers-assets\money-recive.svg" alt="customersimg" className="customersimg" />
                                                </div>
                                                <p className="innercustomerscardtext">Total Cash Back</p>
                                            </div>
                                        </div>
                                        <p className="innercustomerscardnumber">${affliatesDetail?.stats[0]?.totalCashback || 0}</p>
                                    </div>
                                    <div className="customerscard">
                                        <div className="uppercustomerscard">
                                            <div className="innercustomerscard">
                                                <div className="innercustomersimg colorthree">
                                                    <img src="\assests\customers-assets\moneys.svg" alt="customersimg" className="customersimg" />
                                                </div>
                                                <p className="innercustomerscardtext">Total Revenue</p>
                                            </div>
                                        </div>
                                        <p className="innercustomerscardnumber">${(user?.role === 'admin' && affliatesDetail?.stats[0]?.totalTomiRevenue) || (user?.role === 'affiliate' && affliatesDetail?.stats[0]?.totalAffiliateRevenue) || (user?.role === 'agent' && affliatesDetail?.stats[0]?.totalAgentRevenue) || 0}</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* affiliate detail of users */}
                         {/* agent detail of users */}
                        {(user?.role === 'agent' && paramName === 'customers') &&
                            <div className="main-customers">
                                <div className="topupperleft">
                                    <div className="customerscard">
                                        <div className="uppercustomerscard">
                                            <div className="innercustomerscard">
                                                <div className="innercustomersimg colorone">
                                                    <img src="\assests\customers-assets\people.svg" alt="customersimg" className="customersimg" />
                                                </div>
                                                <p className="innercustomerscardtext">Total Purchase Amount</p>
                                            </div>
                                        </div>
                                        <p className="innercustomerscardnumber">${affliatesDetail?.stats[0]?.totalPurchaseAmount || 0}</p>
                                    </div>
                                    <div className="customerscard">
                                        <div className="uppercustomerscard">
                                            <div className="innercustomerscard">
                                                <div className="innercustomersimg colortwo">
                                                    <img src="\assests\customers-assets\money-recive.svg" alt="customersimg" className="customersimg" />
                                                </div>
                                                <p className="innercustomerscardtext">Total Cash Back</p>
                                            </div>
                                        </div>
                                        <p className="innercustomerscardnumber">${affliatesDetail?.stats[0]?.totalCashback || 0}</p>
                                    </div>
                                    <div className="customerscard">
                                        <div className="uppercustomerscard">
                                            <div className="innercustomerscard">
                                                <div className="innercustomersimg colorthree">
                                                    <img src="\assests\customers-assets\moneys.svg" alt="customersimg" className="customersimg" />
                                                </div>
                                                <p className="innercustomerscardtext">Total Revenue</p>
                                            </div>
                                        </div>
                                        <p className="innercustomerscardnumber">${(user?.role === 'admin' && affliatesDetail?.stats[0]?.totalTomiRevenue) || (user?.role === 'affiliate' && affliatesDetail?.stats[0]?.totalAffiliateRevenue) || (user?.role === 'agent' && affliatesDetail?.stats[0]?.totalAgentRevenue) || 0}</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* agent detail of users */}
                        {/* jawad
                        {false ||
                            <div className="main-customers">
                                <div className="topupperleft">

                                    <div className="customerscard">
                                        <div className="uppercustomerscard">
                                            <div className="innercustomerscard">
                                                <div className="innercustomersimg colorone">
                                                    <img src="\assests\customers-assets\people.svg" alt="customersimg" className="customersimg" />
                                                </div>
                                                <p className="innercustomerscardtext">Total Customers</p>
                                            </div>
                                        </div>
                                        <p className="innercustomerscardnumber">${affliatesDetail?.customersCount || 0}</p>
                                    </div>
                                    <div className="customerscard">
                                        <div className="uppercustomerscard">
                                            <div className="innercustomerscard">
                                                <div className="innercustomersimg colorone">
                                                    <img src="\assests\customers-assets\people.svg" alt="customersimg" className="customersimg" />
                                                </div>
                                                <p className="innercustomerscardtext">Total Purchase Amount</p>
                                            </div>
                                        </div>
                                        <p className="innercustomerscardnumber">${affliatesDetail?.stats[0]?.totalPurchaseAmount || 0}</p>
                                    </div>
                                    <div className="customerscard">
                                        <div className="uppercustomerscard">
                                            <div className="innercustomerscard">
                                                <div className="innercustomersimg colortwo">
                                                    <img src="\assests\customers-assets\money-recive.svg" alt="customersimg" className="customersimg" />
                                                </div>
                                                <p className="innercustomerscardtext">Total Cash Back</p>
                                            </div>
                                        </div>
                                        <p className="innercustomerscardnumber">${affliatesDetail?.stats[0]?.totalCashback || 0}</p>
                                    </div>
                                    <div className="customerscard">
                                        <div className="uppercustomerscard">
                                            <div className="innercustomerscard">
                                                <div className="innercustomersimg colorthree">
                                                    <img src="\assests\customers-assets\moneys.svg" alt="customersimg" className="customersimg" />
                                                </div>
                                                <p className="innercustomerscardtext">Total Revenue</p>
                                            </div>
                                        </div>
                                        <p className="innercustomerscardnumber">${(user?.role === 'admin' && affliatesDetail?.stats[0]?.totalTomiRevenue) || (user?.role === 'affiliate' && affliatesDetail?.stats[0]?.totalAffiliateRevenue) || (user?.role === 'agent' && affliatesDetail?.stats[0]?.totalAgentRevenue) || 0}</p>
                                    </div>
                                </div>
                            </div>
                        } */}
                        <div className="affiliatesinformation">
                            <h4 className="affiliatesinformationhead text-capitalize"> Information</h4>
                            <div className="affiliatesinformationmain">
                                <div className="affiliatesinformationupper">
                                    {affliatesDetail?.name &&
                                        <div className="ainformationupperleft">
                                            <h6 className="infohead">Name</h6>
                                            <p className="infopara">{affliatesDetail?.name}</p>
                                        </div>
                                    }
                                    <div className="ainformationupperright">
                                        <h6 className="infohead">Created at</h6>
                                        <p className="infopara"> {affliatesDetail?.createdAt?.split('T')[0]?.replaceAll('-', '/')},  {affliatesDetail?.createdAt?.split('T')[1]?.slice(0, 5)}</p>
                                    </div>
                                </div>
                                <div className="affiliatesinformationupper">
                                    {affliatesDetail?.referenceCode &&
                                        <div className="ainformationupperleft">
                                            <h6 className="infohead">Code</h6>
                                            <p className="infopara">{affliatesDetail?.referenceCode}</p>
                                        </div>
                                    }
                                    <div className="ainformationupperright">
                                        <h6 className="infohead">Wallet address </h6>
                                        <p className="infopara">{affliatesDetail?.walletAddress}</p>
                                    </div>
                                </div>
                                <div className="affiliatesinformationupper">
                                    {affliatesDetail?.affiliate &&
                                        <div className="ainformationupperleft">
                                            <h6 className="infohead">Affiliate</h6>
                                            <p className="infopara">{affliatesDetail?.affiliate[0]?.name}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {(user?.role === 'affiliate' && paramName !== 'customers') &&
                            <div className="bottom-graph-main">
                                <h6>Top Customer</h6>
                                <CustomerTable setLoader={setLoader} hide={true} detailTable={affliatesDetail?._id} />
                            </div>
                        }
                        {(user?.role === 'admin' && paramName === 'affiliates') &&
                            <div className="bottom-graph-main">
                                <h6>Top {(user?.role === 'admin' && paramName === 'affiliates') ? 'Agents' : null}</h6>
                                <DataTableADetail setLoader={setLoader} hide={true} detailTable={affliatesDetail?._id} />
                            </div>
                        }
                        {(user?.role === 'admin' && paramName === 'agents') &&
                            <div className="bottom-graph-main">
                                <h6>Top {(user?.role === 'admin' && paramName === 'agents') ? 'Customer' : null}</h6>
                                <CustomerTable setLoader={setLoader} hide={true} detailTable={affliatesDetail?._id} />
                            </div>
                        }
                        {(paramName === 'customers') &&
                            <div className="bottom-graph-main">
                                <h6>Top Purchases</h6>
                                <CustomerPurchases setLoader={setLoader} hide={true} detailTable={affliatesDetail?._id} />
                            </div>
                        }
                    </div>
                </section>
            </div>
        </>
    )
}

export default Affiliatesdetails
