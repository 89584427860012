import Affiliates from "views/Affiliates/Affiliates";
import Affiliatesdetails from "views/Affiliates/Affiliatesdetails";
import Agents from "views/Agents/Agents";
import Customers from "views/Customers/Customers";
import Dashboard from "views/dashboard/Dashboard.js";
import DataTable from "views/DataTable/DataTable.js";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/affiliates",
    name: "Affiliates",
    component: Affiliates,
    layout: "/admin",
  },
  {
    path: "/affiliatesdetails",
    name: "Affiliate Details",
    component: Affiliatesdetails,
    layout: "/admin",
  },
  {
    path: "/agents",
    name: "Agents",
    component: Agents,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
    layout: "/admin",
  },
  {
    path: "/datatable",
    name: "DataTable",
    component: DataTable,
    layout: "/admin",
  }
];
export default routes;
