import { API_URL } from "ApiUrl";
import axios from "axios";
import Loader from "loader/loader";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import "./login.scss";
const Login = () => {
  const [Name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [loader, setLoader] = useState(false)
  const [errors, setErrors] = useState(false)
  const history = useHistory()
  console.log('asdfaasdf', window?.location?.pathname?.split('/')[1])
  // let userPath = window?.location?.pathname?.split('/')[1]
  // let user;
  // if (userPath === 'adminlogin') {
  //   user = 'admins'
  // } else if (userPath === 'affiliate') {
  //   user = 'affiliates'
  // } else if (userPath === 'agent') {
  //   user = 'agents'
  // }
  let toastDesign = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }
  // let user = JSON.parse(localStorage.getItem("user"));
  const createAffliates = async () => {
    const data = {
      username: Name, password: password
    }
    setErrors(true)
    if (Name && password) {
      setLoader(true)
      var config = {
        method: "post",
        url: `${API_URL}/auth/admins/signin`,
        data: data,
        // headers: {
        //     authorization: `Bearer ` + tok
        // }
      };
      axios(config)
        .then(function (response) {
          setLoader(false)
          // console.log('error===>', response?.data?.data?.accessToken);
          localStorage.setItem('accessToken', response?.data?.data?.accessToken)
          localStorage.setItem("refreshToken", response?.data?.data?.refreshToken);
          localStorage.setItem('user', JSON.stringify(response?.data?.data?.user))
          toast.success(response?.data?.message, toastDesign);
          history.push('/admin/dashboard')
        }).catch(function (error) {
          setLoader(false)
          toast.error(error?.response?.data?.message, toastDesign)
          // console.log('error===>', error?.response?.data?.message);
        });
    } else {
      // toast.error('ChainId is missing !')
    }
  }
  return (
    <>

      {loader && <Loader />}
      <section className="main-login">
        <div className="innerlogin">
          <img src="\logo.svg" alt="logo" className="logo" />
          <p className="inputpara">Email</p>
          <input onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter Email" className="logininput" />
          {errors && (Name !== '' || <p className="errorMessage">Email is missing!</p>)}
          <p className="inputpara">Password</p>
          <input onChange={(e) => setPassword(e.target.value)} type="text" placeholder="Enter Password" className="logininput" />
          {errors && (password !== '' || <p className="errorMessage errorMessage2">password is missing !</p>)}
          <login onClick={createAffliates} className="common-btn">Login</login>
        </div>
      </section>
    </>
  );
};

export default Login;
