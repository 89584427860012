// import { useWeb3React } from '@web3-react/core';
// import useAuth from '../../hooks/useAuth';
// import React, { useEffect, useState } from 'react'
// import './loginwallet.scss'
// import Signature from '../../hooks/userSign'
// import { API_URL } from 'ApiUrl';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import Loader from 'loader/loader';
// import { useHistory } from 'react-router';
import { API_URL } from "ApiUrl";
import axios from "axios";
import Loader from "loader/loader";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
const Loginwallet = () => {
    const [Email, setName] = useState('')
    const [password, setPassword] = useState('')
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState(false)
    const [rememberMe, setRememberMe] = useState()
    const history = useHistory()
    let toastDesign = {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }
    const affiliateLogin = async () => {
        const data = {
            email: Email, password: password, rememberMe: rememberMe
        }
        setErrors(true)
        if (Email && password && rememberMe) {
            setLoader(true)
            var config = {
                method: "post",
                url: `${API_URL}/auth/affiliates/signin`,
                data: data,
                // headers: {
                //     authorization: `Bearer ` + tok
                // }
            };
            axios(config)
                .then(function (response) {
                    setLoader(false)
                    // console.log('error===>', response?.data?.data?.accessToken);
                    localStorage.setItem('accessToken', response?.data?.data?.accessToken)
                    localStorage.setItem("refreshToken", response?.data?.data?.refreshToken);
                    localStorage.setItem('user', JSON.stringify(response?.data?.data?.user))
                    toast.success(response?.data?.message, toastDesign);
                    if (response?.data?.data?.user?.isRequirePasswordChange) {
                        toast.info('Change Password With Strong One', toastDesign);
                        history.push('/createnewpassword')
                    } else {
                        history.push('/admin/dashboard')
                    }

                }).catch(function (error) {
                    setLoader(false)
                    toast.error(error?.response?.data?.message, toastDesign)
                    // console.log('error===>', error?.response?.data?.message);
                });
        } else {
            toast.info('Select Checkbox First!')
        }
    }
    // const { account } = useWeb3React();
    // const { login, logout } = useAuth();
    // const { userSign } = Signature()
    // const history = useHistory()
    // const [loader, setLoader] = useState(false)
    // const connectMetamask = () => {
    //     localStorage.setItem("connectorId", "injected");
    //     if (account) {
    //         logout();
    //         localStorage.setItem("flag", false)
    //     } else {
    //         localStorage.setItem("flag", true)
    //         login("injected");
    //     }
    // };
    // const trustWallet = async () => {
    //     localStorage.setItem("flag", "true");
    //     localStorage.setItem("connectorId", "walletconnect");
    //     if (account) {
    //         logout();
    //     } else {
    //         login("walletconnect");
    //     }
    // };
    // let toastDesign = {
    //     position: "top-right",
    //     autoClose: 3000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    // }
    // const loginUser = async () => {
    //     if (account) {
    //         const sign = await userSign(account);
    //         if (sign) {
    //             let data = { walletAddress: account, sign }
    //             var config = {
    //                 method: "post",
    //                 url: `${API_URL}/auth/affiliates/signin`,
    //                 // headers: {
    //                 //   authorization: `Bearer ` + tok
    //                 // },
    //                 data: data,
    //             };
    //             axios(config)
    //                 .then(function (response) {
    //                     setLoader(false);
    //                     // textCopiedFun();
    //                     // console.log("response?.data", response?.data)
    //                     localStorage.setItem("accessToken", response?.data?.data?.accessToken);
    //                     localStorage.setItem("refreshToken", response?.data?.data?.refreshToken);
    //                     localStorage.setItem("user", JSON.stringify(response?.data?.data));
    //                     history.push('/admin/dashboard')
    //                     window.location.reload()
    //                     toast.success("Login Successfull", toastDesign)
    //                 })
    //                 .catch(function (error) {
    //                     logout()
    //                     localStorage.clear()
    //                     setLoader(false);
    //                     toast.error(error.response.data.message, toastDesign);
    //                 });
    //         } else {
    //             logout()
    //             localStorage.clear()
    //         }

    //     }
    // }
    // useEffect(() => {
    //     loginUser()
    // }, [account])
    // useEffect(() => {
    //     logout()
    //     localStorage.clear()
    // }, [])
    return (
        <>
            {loader && <Loader />}
            {/* <section className="loginwallet">
                <img src="\logo.svg" alt="logo" className="logo" />
                <div className="loginwalletmain">
                    <h5 className="mainhead">Connect Wallet</h5>
                    <div className="walletmains">
                        {account ?
                            <div onClick={connectMetamask} className="innerwallet">
                                <p onClick={connectMetamask} className="walletpara">Disconnect</p>
                            </div>
                            :
                            <>
                                <div onClick={connectMetamask} className="innerwallet">
                                    <img src="\assests\wallet\metamask.svg" alt="walletimg" className="walletimg" />
                                    <p onClick={connectMetamask} className="walletpara">Metamask</p>
                                </div>
                                <div onClick={trustWallet} className="innerwallet">
                                    <img src="\assests\wallet\walletconnect.svg" alt="walletimg" className="walletimg" />
                                    <p className="walletpara">WalletConnect</p>
                                </div>
                            </>
                        }

                    </div>
                </div>
            </section> */}
            <section className="main-login">
                <div className="innerlogin">
                    <img src="\logo.svg" alt="logo" className="logo" />
                    <div className="newflow-box">
                        <h4>Log into your affiliate account</h4>
                        <p>Please enter your credentials below to log into your account.</p>
                    </div>
                    <p className="inputpara">Email</p>
                    <input
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        placeholder="Enter Email"
                        className="logininput"
                    />
                    {errors &&
                        (Email !== "" || <p className="errorMessage">Email is missing!</p>)}
                    <p className="inputpara">Password</p>
                    <input
                        onChange={(e) => setPassword(e.target.value)}
                        type="text"
                        placeholder="Enter Password"
                        className="logininput"
                    />
                    {errors &&
                        (password !== "" || (
                            <p className="errorMessage errorMessage2">
                                password is missing !
                            </p>
                        ))}
                    <div className="newflow remove-forgot-wrapper">
                        <div className="remember-me-check-text">
                            <div className="checkboxmain">
                                <label class="checkbox" for="yellow">
                                    <input
                                        required
                                        type="checkbox"
                                        name="color"
                                        value="yellow"
                                        id="yellow"
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                    />
                                    <span class="checkmark"></span>
                                </label>
                                {/* <p className="checkboxpara">
                  I have read and agree to Prime trader’s Terms of use and
                  Privacy Policy and i authorize Prime Traders to debit my
                  chosen payment method for the amount above on 2023-03-10
                </p> */}
                                <p className="checkboxpara">Keep me logged in</p>
                            </div>
                        </div>
                        <div className="forgot-wrapper">
                            <Link to={"/forgotpassword?affiliates"} className="forgot-text">
                                Forgot your password?
                            </Link>
                        </div>
                    </div>
                    <login onClick={affiliateLogin} className="common-btn">
                        Login
                    </login>
                </div>
            </section>
        </>
    )
}

export default Loginwallet
