import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import Login from "components/login/Login";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loginwallet from "components/Loginwallet/Loginwallet";
import AgentLogin from "components/Loginwallet/AgentLogin";
import Kickback from "components/Invitecode/Kickback";
import Afterconnect from "components/Thankyou/Afterconnect";
import Banner from "components/Welcome/Banner";
import useEagerConnect from "hooks/useEagerConnect";
import CreateNewPassword from "components/CreateNewPassword/CreateNewPassword";
import Success from "components/Success/Success";
import ForgotYourPassword from "components/ForgotYourPassword/ForgotYourPassword";

function App() {

  let user = JSON.parse(localStorage.getItem("user"));
  // useEagerConnect();
  return (
    <>
      <ToastContainer style={{ fontSize: 20 }} />
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Kickback} />
          <Route path="/loginwallet" component={Loginwallet} />
          <Route path="/adminLogin" render={(props) => <Login {...props} />} />
          <Route path="/walletBanner" render={(props) => <Banner {...props} />} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route exact path='/thankyou' component={Afterconnect} />

          <Route path="/user" render={(props) => <Banner {...props} />} />
          <Route path="/affiliate" component={Loginwallet} />
          <Route path="/agent" component={AgentLogin} />
          <Route path="/createnewpassword" component={CreateNewPassword} />
          <Route path="/success" component={Success} />
          <Route path="/forgotpassword" component={ForgotYourPassword} />
          <Route path="/adminLogin" render={(props) => <Login {...props} />} />
          <Redirect to="/" />    
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
