import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import Modal from 'react-bootstrap/Modal';
import DataTable from "views/DataTable/DataTable";
import CustomerTable from "views/DataTable/CustomerTable";
import DataTableADetail from "views/DataTable/DataTableADetail";
import { API_URL } from "ApiUrl";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "loader/loader";
import { useHistory } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { useDispatch } from 'react-redux'
import { incrementByAmount } from '../../reduxToolkit/counterSlice'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range';
import { DateRangePicker } from 'react-date-range';
import { DateRange } from 'react-date-range';
import { Email } from "@material-ui/icons";

function Dashboard() {
  const dispatch = useDispatch()
  const history = useHistory();
  const [Name, setName] = useState('')
  const [walletaddress, setWallet] = useState('')
  const [percentage, setPercentage] = useState('')
  const [email, setEmail] = useState('')
  const [referenceCode, setReferenceCode] = useState('')
  const [errors, setErrors] = useState(false)
  const [affiStatus, setAffiStatus] = useState(false)
  const [loader, setLoader] = useState(false)
  const [show, setShow] = useState(false);
  const [calenderShow, setCalenderShow] = useState('');
  const [valid, setValid] = useState(false)
  const [agentsData, setAgentsData] = useState('')
  const [graphData, setGraphData] = useState()
  const [allStatTrue, setAllStatTrue] = useState(false)
  const [totalCashBack, setTotalCashBack] = useState(0)
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [limit, setLimit] = useState('year')
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  // // console.log('range', state)
  let toastDesign = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let user = JSON.parse(localStorage.getItem("user"));
  const createAffliates = async () => {


    var data;
    if (user?.role === 'admin') {
      if (referenceCode) {
        data = {
          name: Name, email, walletAddress: walletaddress, revenuePercentage: percentage, referenceCode
        }
      } else {
        data = {
          name: Name, email, walletAddress: walletaddress, revenuePercentage: percentage,
        }
      }

    } else if (user?.role === 'affiliate') {
      data = {
        name: Name, email, walletAddress: walletaddress, cashbackPercentage: percentage
      }
    }

    setErrors(true)
    var rolee;
    if (user?.role === 'admin') {
      rolee = 'affiliates'
    } else if (user?.role === 'affiliate') {
      rolee = 'agents'
    }
    if (Name && walletaddress && percentage && email) {
      setShow(false)
      setLoader(true)
      let tok = localStorage.getItem("accessToken");
      var config = {
        method: "post",
        url: `${API_URL}/${rolee}`,
        data: data,
        headers: {
          authorization: `Bearer ` + tok
        }
      };
      axios(config)
        .then(function (response) {
          setLoader(false)
          toast.success(response?.data?.message, toastDesign);
          setShow(false)
          setWallet('')
          setName('')
          setPercentage('')
          setReferenceCode('')
          setErrors(false)
          setAffiStatus(!affiStatus)
        }).catch(function (error) {
          setLoader(false)
          if (error.code == 401) {
            refreshToken()
          }
          setShow(false)
          // // console.log('error', error)
          toast.error(error?.response?.data?.message, toastDesign)
          // // console.log(error);
          setShow(false)
          setWallet('')
          setName('')
          setPercentage('')
          setReferenceCode('')
          setErrors(false)
        });
    } else {
      // toast.error('Fill All Fields !')
    }


  }
  const clsedHandler = () => {
    setShow(false)
    setWallet('')
    setName('')
    setPercentage('')
    setErrors(false)
  }
  const refreshToken = () => {


    let tok = localStorage.getItem("refreshToken");
    var data = ({
      refreshToken: tok,
    });
    var config = ''
    if (user?.role == 'admin') {
      config = {
        method: "post",
        url: `${API_URL}/auth/admins/refresh-token`,
        headers: {
          authorization: `Bearer ` + tok
        },
        data: data,
      };
    } else {
      config = {
        method: "post",
        url: `${API_URL}/auth/affiliate/refresh-token`,
        headers: {
          authorization: `Bearer ` + tok
        },
        data: data,
      };
    }

    axios(config)
      .then(function (response) {
        localStorage.setItem("accessToken", response?.data?.data?.accessToken);
        window?.location?.reload();
      })
      .catch(function (error) {
        if (user?.role == 'admin') {
          if (error.code == 401) {
            localStorage.clear();
            history.push("/adminlogin");
            history.push("/affiliate");
          }
        } else {
          if (error.code == 401) {
            localStorage.clear();
            history.push("/affiliate");
          }
        }


        toast.error(error.response.data.message);
      });
  }

  const referenceCodeFunc = async () => {
    try {
      setLoader(true)
      let tok = localStorage.getItem("accessToken");
      var data = ({
        referenceCode,
      });
      var config = {
        method: "post",
        url: `${API_URL}/affiliates/challenge/validate/reference-code`,
        headers: {
          authorization: `Bearer ` + tok
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          // toast.success('Reference Code valid', toastDesign)
          setLoader(false)
          setValid(true)
        })
        .catch(function (error) {
          setValid(false)
          if (user?.role == 'admin') {
            if (error.code == 401) {
              localStorage.clear();
              history.push("/adminlogin");
              history.push("/affiliate");
            }
          } else {
            if (error.code == 401) {
              localStorage.clear();
              history.push("/affiliate");
            }
          }
          // toast.error(error?.response?.data?.message, toastDesign)
          setLoader(false)
        });


    } catch (error) {

      // // console.log('res of the data', error)
    }

  }
  // useEffect(() => {
  //   if (referenceCode?.length === 10) {
  //     referenceCodeFunc()
  //   }
  // }, [])
  // var labbb = agentsData?.stats?.map(function(item){ return ( item?.periodVal )}).sort((a,b)=>{ return (a < b ? -1 : (a > b ? 1 : 0))});
  const labbb = graphData?.stats?.map((item) => { return (item?.periodVal) })
  // // console.log("labbbbbb", labbb)
  const data = {
    labels: labbb,
    datasets: [
      {
        label: "Total CB",
        data: graphData?.stats?.map((item) => { return (item?.totalCashback) }),
        fill: true,
        backgroundColor: "#fff1f896",
        borderColor: "#fc3fa1"
      },
      {
        label: "Net Revenue",
        data: graphData?.stats?.map((item) => { return (item?.netRevenue) }),
        fill: true,
        backgroundColor: "#f3edfa96",
        borderColor: "#8843e1"
      }
    ],

  };
  let options = {
    plugins: {
      legend: {
        maxWidth: "0px",
        pointStyle: 'triangle !important',
        align: 'center',
        // position: 'right',
        // display: false,
        borderRadius: "999px !important",
        labels: {
          pointStyleWidth: 1,
          useBorderRadius: true,
          usePointStyle: true,
          pointStyle: 'circle',
        },

      },

    },


  }
  const handlerOption = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    if (value.length < 11) {
      setReferenceCode(value)
      // // console.log(value.length, 'eee');

      if (value?.length >= 5 && value?.length < 11) {
        referenceCodeFunc()

      }
    } else {

    }

    // (e)
  }
  const AgentsGraphDataFunc = async (stateValStart, stateValEnd) => {
    let tok = localStorage.getItem("accessToken");
    let month = "year"
    try {
      setLoader(true)
      let res;
      if (stateValStart && stateValEnd) {
        res = await axios.get(`${API_URL}/${user?.role}s/stats?limit=30&&period=${limit}&&from=${stateValStart}&&to=${stateValEnd}`, {
          headers: {
            authorization: `Bearer ` + tok
          }
        })
      } else {
        res = await axios.get(`${API_URL}/${user?.role}s/stats?limit=30&&period=${limit}`, {
          headers: {
            authorization: `Bearer ` + tok
          }
        })
      }

      setLoader(false)
      if (allStatTrue) {
        setAgentsData(res?.data?.data)
      }
      //to clear calender state
      setState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ])
      // dispatch(incrementByAmount(res?.data?.data))
      setGraphData(res?.data?.data)
      //total cashback
      let tCashBack = 0;
      res?.data?.data?.stats?.forEach(item => { tCashBack += item?.totalCashback; });
      setTotalCashBack(tCashBack)
      //total netRevenue
      let tRevenue = 0;
      res?.data?.data?.stats?.forEach(item => { tRevenue += item?.netRevenue; });
      setTotalRevenue(tRevenue)
      // // console.log('resss', res?.data?.data)
    } catch (error) {
      if (error.code == 401) {
        refreshToken()
      }
      setLoader(false)
      // // console.log('res error', error)
    }
  }
  const AgentsAllTimeStats = async () => {
    let tok = localStorage.getItem("accessToken");
    try {
      setLoader(true)
      const res = await axios.get(`${API_URL}/${user?.role}s/all-stats`, {
        headers: {
          authorization: `Bearer ` + tok
        }
      })
      setLoader(false)
      setAgentsData(res?.data?.data)
      dispatch(incrementByAmount(res?.data?.data))
      //total cashback
      setTotalCashBack(res?.data?.data?.stats[0]?.totalCashback)
      //total netRevenue
      setTotalRevenue(res?.data?.data?.stats[0]?.netRevenue)
      // // console.log('resss', res?.data?.data)
    } catch (error) {
      if (error.code == 401) {
        refreshToken()
      }
      setLoader(false)
      // // console.log('res error', error)
    }
  }
  useEffect(() => {
    if (!allStatTrue) {
      AgentsAllTimeStats()
    }
    AgentsGraphDataFunc()
  }, [limit])
  // window.$("#dropdownMenuButton2").click(function (e) {
  //   e.stopPropagation();
  // })
  useEffect(() => {
    let stateValStart = state[0]?.startDate?.toISOString()?.split('T')[0]
    let stateValEnd = state[0]?.endDate?.toISOString()?.split('T')[0]

    if (stateValStart !== stateValEnd) {
      setTimeout(() => {
        setCalenderShow('')
        AgentsGraphDataFunc(stateValStart, stateValEnd)
      }, 1000);

    }
    // // console.log('range', stateValStart, stateValEnd)
  }, [state])
  return (
    <>
      {loader && <Loader />}
      <div className="content">
        <section className="main-dashboard">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-sm-12 p-0">
                <div className="topupperleft">
                  {user?.role == 'admin' ?
                    <div className="homecard">
                      <div className="upperhomecard">
                        <div className="innerhomecard">
                          <div className="inneraffiliatesimg colorone">
                            <img src="\assests\home-assets\affiliateimg.svg" alt="affiliatesimg" className="affiliatesimg" />
                          </div>
                          <p className="innerhomecardtext">CB Affiliates</p>
                        </div>
                        <button className="addaffiliates" onClick={handleShow}>
                          <img src="\assests\home-assets\addaffiliatesimg.svg" alt="addaffiliatesimg" className="addaffiliatesimg" />
                          Add
                        </button>

                      </div>
                      <p className="innerhomecardnumber">{agentsData?.affiliatesCount || 0}</p>
                    </div>
                    :
                    ""

                  }
                  {user?.role === 'agent' ||
                    <div className={`homecard ${user?.role === 'affiliate' && 'w-50'}`}>
                      <div className="upperhomecard">
                        <div className="innerhomecard">
                          <div className="inneraffiliatesimg colortwo">
                            <img src="\assests\home-assets\agentsimg.svg" alt="affiliatesimg" className="affiliatesimg" />
                          </div>
                          <p className="innerhomecardtext">CB Agents</p>
                        </div>
                        {user?.role == 'affiliate' ?
                          <button className="addaffiliates" onClick={handleShow}>
                            <img src="\assests\home-assets\addaffiliatesimg.svg" alt="addaffiliatesimg" className="addaffiliatesimg" />
                            Add
                          </button>
                          :
                          ""
                        }

                      </div>
                      <p className="innerhomecardnumber">{agentsData?.agentsCount || 0}</p>
                    </div>
                  }
                  <div className={`homecard ${user?.role === 'affiliate' && 'w-50'} ${user?.role === 'agent' && 'w-100'} `}>
                    <div className="upperhomecard">
                      <div className="innerhomecard">
                        <div className="inneraffiliatesimg colorthree">
                          <img src="\assests\home-assets\customersimg.svg" alt="affiliatesimg" className="affiliatesimg" />
                        </div>
                        <p className="innerhomecardtext">CB Customers</p>
                      </div>
                    </div>
                    <p className="innerhomecardnumber">{agentsData?.customersCount || 0}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 p-0">
                <div className="maingraphsquare">
                  <div className="graph">
                    <div className="revenue_heading_calender">
                      <h3 className="h3">Revenues</h3>
                      <button type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="true" aria-haspopup="true" className="calenderFilter">
                        <p>{limit}</p>

                      </button>
                      <div class="dropdown-menu" id="dropdownMenuButtonInner" aria-labelledby="dropdownMenuButton">
                        <span className="">
                          <a onClick={() => { setAllStatTrue(true); setLimit('day'); }} class="dropdown-item">Day</a>
                          <a onClick={() => { setAllStatTrue(true); setLimit('month') }} class="dropdown-item">Month</a>
                          <a onClick={() => { setAllStatTrue(true); setLimit('week') }} class="dropdown-item">Week</a>
                          <a onClick={() => { setAllStatTrue(true); setLimit('year') }} class="dropdown-item">Year</a></span>


                      </div>
                      <div class="dropdown classCalender">
                        <button onClick={() => setCalenderShow('show')} class="btn bg-none border-0 " >
                          <img src="\assests\calender.svg" alt="" />
                        </button>
                        <div class={`dropdown-menu ${calenderShow}`} aria-labelledby="">
                          <a class="dropdown-ite" >
                            <div onClick={() => setCalenderShow('')} className="text-right p-2">
                              <img src="\assests\navbar-assets\close-circle.svg" alt="" srcset="" />
                            </div>

                            <DateRange

                              value={state}
                              editableDateInputs={true}
                              onChange={item => { setState([item.selection]) }}
                              moveRangeOnFirstSelection={false}
                              ranges={state}
                            /></a>
                        </div>
                      </div>
                    </div>











                    <Line options={options} data={data} />
                    {/* <img src="\assests\home-assets\graphimg.png" alt="graphimg" className="graphimg w-100" /> */}
                  </div>
                  <div className="totals">
                    <div className="totalcard">
                      <div className="totalcardimg">
                        <img src="\assests\home-assets\totalcbimg.svg" alt="totalcardinnerimg" className="totalcardinnerimg" />
                      </div>
                      <h6 className="totalcardhead">${totalCashBack || 0}</h6>
                      <p className="totalcardtext">Total CB</p>
                    </div>
                    <div className="totalcard">
                      <div className="totalcardimg">
                        <img src="\assests\home-assets\netrevenueimg.svg" alt="totalcardinnerimg" className="totalcardinnerimg" />
                      </div>
                      <h6 className="totalcardhead">${totalRevenue || 0}</h6>
                      <p className="totalcardtext">Net Revenue </p>
                    </div>
                  </div>
                </div>
                <div className="bottom-graph-main">
                  <div className="d-flex justify-content-between">
                    <h6>{user?.role == 'admin' ? "Top Affiliates" : user?.role == 'agent' ? 'My Top Customer' : "Top Agents"}</h6>
                    {/* <h6> <span >Reference Code :</span>  {user?.referenceCode}</h6> */}
                  </div>

                  {user?.role == 'admin' ?
                    <DataTable affiStatus={affiStatus} setLoader={setLoader} hide={true} />
                    :
                    user?.role === 'agent' ?
                      <CustomerTable affiStatus={affiStatus} setLoader={setLoader} hide={true} />
                      :
                      <DataTableADetail affiStatus={affiStatus} setLoader={setLoader} hide={true} />
                  }

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal className="addaffiliatemodal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{user?.role == 'admin' ? "Add Affiliate" : "Add Agent"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalinputs">
            <div className="addinput">
              <p className="addinputpara">Name</p>
              <input onChange={(e) => setName(e.target.value)} value={Name} type="text" placeholder="Name" />
              {errors && (Name !== '' || <p className="errorMessage">Name is missing!</p>)}
            </div>
            <div className="addinput">
              <p className="addinputpara">Wallet address</p>
              <input onChange={(e) => setWallet(e.target.value)} value={walletaddress} type="text" placeholder="Wallet address" />
              {errors && (walletaddress !== '' || <p className="errorMessage errorMessage2">Walletaddress is missing !</p>)}
            </div>
            <div className="addinput">
              <p className="addinputpara">Email</p>
              <input onChange={(e) => setEmail(e.target.value)} value={email} type="text" placeholder="Email address" />
              {errors && (email !== '' || <p className="errorMessage errorMessage2">Email is missing !</p>)}
            </div>
            <div className="addinput">
              <p className="addinputpara">Percentage</p>
              <input value={percentage} onChange={(e) => { user?.role === 'affiliate' ? e.target.value <= user?.revenuePercentage && setPercentage(e.target.value) : setPercentage(e.target.value) }} type="number" placeholder="Amount of %" />
              {user?.role === 'affiliate' && <p className="errorMessage errorMessage2  text-secondary">Percentage must be less than equal to {user?.revenuePercentage}</p>}
              {errors && (percentage !== '' || <p className="errorMessage errorMessage2">Percentage is missing !</p>)}
            </div>
            {user?.role === 'admin' ?
              <div className="addinput">
                <p className="addinputpara">Reference Code (Optional)</p>
                <input onChange={(e) => handlerOption(e)} type="text" value={referenceCode} placeholder="Reference Code (Optional)" />
                <p className="errorMessage errorMessage2 text-secondary">{(referenceCode && !valid) ? <span className="text-danger"> Reference Code not valid!</span> : 'Length must be greater than 5 and less than 11 characters long '}</p>
              </div>
              :
              ""

            }
          </div>
          <div className="addaffiliatesbtn">
            <button className="white-btn" onClick={clsedHandler}>Cancel</button>
            <button disabled={referenceCode && !valid} onClick={createAffliates} className={(referenceCode && !valid) ? "common-btn btn-secondary cNPointer" : "common-btn"}> {user?.role == 'admin' ? "Add Affiliate" : "Add Agent"}</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Dashboard;
