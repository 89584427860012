import React from 'react'
import { Link } from 'react-router-dom'
import './afterconnect.scss'

const Afterconnect = () => {
    return (
        <>
            <section className="afterconnect">
                <div className="theme-container">
                    <div className="col-sm-12 p-0">
                        <div className="afterconnect-headings">
                            <h2 className="afterconnecthead">Thank you for connecting your wallet</h2>
                            <p className="afterconnectpara">If you’ll win tokens at the daily auctions this wallet will receive  5% of the USDT paid</p>
                           
                            <a href='https://tomi.com/' className="common-btn">Go to tomi.com</a>
                       
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Afterconnect