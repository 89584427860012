import { API_URL } from 'ApiUrl'
import axios from 'axios'
import Loader from 'loader/loader'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import CustomerTable from 'views/DataTable/CustomerTable'
import './customers.scss'
import { useSelector } from 'react-redux'

const Customers = () => {
  const statDataRedux = useSelector((state) => state.counter.value)
  const [loader, setLoader] = useState(false)
  let user = JSON.parse(localStorage.getItem("user"));
  const { history } = useHistory()
  const [affliatesDetail, setAffiliateDetail] = useState()
  const [getAdminAffState, setgetAdminAffState] = useState()
  const getAgentsDetail = async () => {
    let tok = localStorage.getItem("accessToken");
    try {
      setLoader(true)
      const res = await axios.get(`${API_URL}/${user.role}s/${user?._id}`, {
        headers: {
          authorization: `Bearer ` + tok
        }
      })
      setLoader(false)
      // console.log('first', res?.data?.data)
      setAffiliateDetail(res?.data?.data)
      // // console.log('res', res?.data?.data?.affiliates)
    } catch (error) {
      if (error.code == 401) {
        refreshToken()
      }
      setLoader(false)
      // // console.log('res error', error)
    }
  }
  const getAdminAffStats = async () => {
    let tok = localStorage.getItem("accessToken");
    try {
      setLoader(true)
      const res = await axios.get(`${API_URL}/purchases/revenue-stats`, {
        headers: {
          authorization: `Bearer ` + tok
        }
      })
      setLoader(false)
      // // console.log('first', res?.data?.data)
      setgetAdminAffState(res?.data?.data)
      // // console.log('res', res?.data?.data?.affiliates)
    } catch (error) {
      if (error.code == 401) {
        refreshToken()
      }
      setLoader(false)
      // // console.log('res error', error)
    }
  }
  const refreshToken = () => {


    let tok = localStorage.getItem("refreshToken");
    var data = ({
      refreshToken: tok,
    });
    var config = ''
    if (user?.role == 'admin') {
      config = {
        method: "post",
        url: `${API_URL}/auth/admins/refresh-token`,
        headers: {
          authorization: `Bearer ` + tok
        },
        data: data,
      };
    } else {
      config = {
        method: "post",
        url: `${API_URL}/auth/affiliate/refresh-token`,
        headers: {
          authorization: `Bearer ` + tok
        },
        data: data,
      };
    }

    axios(config)
      .then(function (response) {
        localStorage.setItem("accessToken", response?.data?.data?.accessToken);
        window?.location?.reload();
      })
      .catch(function (error) {
        if (user?.role == 'admin') {
          if (error.code == 401) {
            localStorage.clear();
            history.push("/");
          }
        } else {
          if (error.code == 401) {
            localStorage.clear();
            history.push("/");
          }
        }


        toast.error(error.response.data.message);
      });
  }
  useEffect(() => {
    if (user?._id && user?.role != 'admin') {
      getAgentsDetail()
    }
    getAdminAffStats()
  }, [user?._id])
  return (
    <>
      {loader && <Loader />}
      <div className="content">
        <section className="main-customers">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-sm-12 p-0">
                <div className="topupperleft">
                  {user?.role === 'admin' ?
                    <div className="customerscard">
                      <div className="uppercustomerscard">
                        <div className="innercustomerscard">
                          <div className="innercustomersimg colorone">
                            <img src="\assests\customers-assets\people.svg" alt="customersimg" className="customersimg" />
                          </div>
                          <p className="innercustomerscardtext">Total Customers</p>
                        </div>
                      </div>
                      <p className="innercustomerscardnumber">{statDataRedux?.customersCount || 0}</p>
                    </div>
                    :
                    <div className="customerscard">
                      <div className="uppercustomerscard">
                        <div className="innercustomerscard">
                          <div className="innercustomersimg colorone">
                            <img src="\assests\customers-assets\people.svg" alt="customersimg" className="customersimg" />
                          </div>
                          <p className="innercustomerscardtext">Total Customers</p>
                        </div>
                      </div>
                      <p className="innercustomerscardnumber">{statDataRedux?.customersCount || 0}</p>
                    </div>
                  }
                  <div className="customerscard">
                    <div className="uppercustomerscard">
                      <div className="innercustomerscard">
                        <div className="innercustomersimg colortwo">
                          <img src="\assests\customers-assets\money-recive.svg" alt="customersimg" className="customersimg" />
                        </div>
                        <p className="innercustomerscardtext">Total Cash Back</p>
                      </div>
                    </div>
                    {user?.role === 'admin' ?
                      <p className="innercustomerscardnumber">${getAdminAffState?.totalCustomersCashback || 0}</p>
                      :
                      <p className="innercustomerscardnumber">${affliatesDetail?.stats[0]?.totalCashback || 0}</p>
                    }
                  </div>
                  <div className="customerscard">
                    <div className="uppercustomerscard">
                      <div className="innercustomerscard">
                        <div className="innercustomersimg colorthree">
                          <img src="\assests\customers-assets\moneys.svg" alt="customersimg" className="customersimg" />
                        </div>
                        <p className="innercustomerscardtext">Total Revenue</p>
                      </div>
                    </div>
                    {user?.role === 'admin' ?
                      <p className="innercustomerscardnumber">${getAdminAffState?.totalTomiRevenue || 0}</p>
                      :
                      <p className="innercustomerscardnumber">${(user?.role === 'admin' && affliatesDetail?.stats[0]?.totalTomiRevenue) || (user?.role === 'affiliate' && affliatesDetail?.stats[0]?.totalAffiliateRevenue) || (user?.role === 'agent' && affliatesDetail?.stats[0]?.totalAgentRevenue) || 0}</p>
                    } </div>
                </div>
                <div className="bottom-graph-main">
                  <h6>All Customers</h6>
                  {user?.role == 'affiliate' ?
                    <CustomerTable setLoader={setLoader} hide={true} detailTable={user?._id} />
                    :
                    <CustomerTable setLoader={setLoader} />
                  }

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Customers
