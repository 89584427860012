import React from "react";
import successImg from "../../assets/img/dashboardimg/successImg.svg";
import { useHistory } from "react-router";

const Success = () => {
  const history = useHistory();
  let user = JSON.parse(localStorage.getItem("user"));
  const login = () => {
    if (user.role === 'affiliate') {
      history.push('/affiliate')
    } else if (user.role === 'agent') {
      history.push('/agent')
    }
  }
  return (
    <section className="main-login">
      <div className="innerlogin">
        <img src="\logo.svg" alt="logo" className="logo" />
        <div className="successImg-box">
          <img src={successImg} alt="successImg" />
          <p className="inputpara">Success!</p>
          <p>Your Password has been successfuly changed</p>
        </div>
        <login className="common-btn" onClick={login}>Login</login>
      </div>
    </section>
  );
};

export default Success;
