import * as React from 'react';
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import './demo.scss'

export default function UsePagination({ prop, setPageNo }) {
    const { items } = usePagination({
        count: prop?.pages,
    });
    React.useEffect(() => {
        for (let i = 0; i <= items?.length; i++) {
            if (items[i]?.selected) {
                setPageNo(items[i]?.page)
            }
        }
    }, [items])


    // // console.log('pagination', items)
    return (
        <nav className='demo'>
            <ul className='pagination'>
                {items.map(({ page, type, selected, ...item }, index) => {
                    let children = null;

                    if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                        children = '…';
                    } else if (type === 'page') {
                        children = (
                            <a
                                className={selected ? 'page-link active' : 'page-link'}
                                // style={{
                                //   fontWeight: selected ? 'active' : undefined,
                                // }}
                                {...item}
                                href="#"
                            >
                                {page}
                            </a>
                        );
                    } else {
                        children = (
                            <button className='page-link '  {...item}>
                                {type}
                            </button>
                        );
                    }

                    return <li className='page-item' key={index}>{children}</li>;
                })}
            </ul>
        </nav>
    );
}
