
import { useWeb3React } from "@web3-react/core";
import { API_URL } from "ApiUrl";
import axios from "axios";
import useAuth from "hooks/useAuth";
import Loader from "loader/loader";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Signature from "../../hooks/userSign";
import "./banner.scss";

const Banner = () => {
  const referenceCode = window?.location?.search?.split('?')[1]
  // console.log('first====', referenceCode)
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const { userSign } = Signature()
  let toastDesign = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }
  const history = useHistory()
  const [loader, setLoader] = useState(false)
  const connectMetamask = () => {
    localStorage.setItem("connectorId", "injected");
    if (account) {
      logout();
      localStorage.setItem("flag", false)
    } else {
      localStorage.setItem("flag", true)
      login("injected");
    }
  };
  const trustWallet = async () => {
    localStorage.setItem("flag", "true");
    localStorage.setItem("connectorId", "walletconnect");
    if (account) {
      logout();
    } else {
      login("walletconnect");
    }
  };


  const getdata = async () => {
    if (account) {
      setLoader(true)
      window.$('#exampleModalWallet').modal('hide')
      const sign = await userSign(account);
      let data = { walletAddress: account, sign, referenceCode }
      var config = {
        method: "post",
        url: `${API_URL}/customers`,
        // headers: {
        //   authorization: `Bearer ` + tok
        // },
        data: data,
      };
      axios(config)
        .then(function (response) {
          setLoader(false);
          // textCopiedFun();
          window.$('#exampleModalWallet').modal('hide')
          // console.log("response?.data", response?.data)
          logout()
          // localStorage.setItem("accessToken", response?.data?.data?.accessToken);
          // localStorage.setItem("refreshToken", response?.data?.data?.refreshToken);
          // localStorage.setItem("user", JSON.stringify(response?.data?.data));
          logout()
          history.push('/thankyou')
          toast.success("Customer created successfully", toastDesign)
        })
        .catch(function (error) {
          window.$('#exampleModalWallet').modal('hide')
          logout()
          setLoader(false);
          toast.error(error.response.data.message, toastDesign);
        });
    }
  }
  useEffect(() => {

    getdata()

  }, [account])
  return (
    <>
      {loader && <Loader />}
      <section className="banner">
        <div className="container-fluid">
          <div className="col-sm-12 p-0">
            <div className="banner-headings">
              <h2 className="bannerhead">Welcome to tomi Kickbacks</h2>
              <p className="bannerpara">Connect your wallet and get 5% cashback of every purchase you <br /> make on tomi token daily auctions</p>
              <a className='w-100'>
                <button className="common-btn" onClick={() => window.$('#exampleModalWallet').modal('show')}>Connect Wallet</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className='walletmodal'>
        <div class="modal" id="exampleModalWallet" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{account ? 'Disconnect' : 'Connect Wallet'}</h5>
                <button onClick={() => window.$('#exampleModalWallet').modal('hide')} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                  <img src='\assestssss\entypo_cross.svg' alt='img' className='img-fluid' />
                </button>
              </div>
              <div class="modal-body">
                <div className='maincard'>
                  <div onClick={connectMetamask} className='metamask'>
                    <img src='\assestssss\metamask 2.svg' alt='img' className='img-fluid' />
                    <p>{'MetaMask'} </p>
                  </div>
                  <div onClick={trustWallet} className='metamask'>
                    <img src='\assestssss\Group.svg' alt='img' className='img-fluid' />
                    <p>{'WalletConnect'} </p>
                  </div>
                </div>
                <div className='brdr'></div>
                <div className='end'>
                  <p>Clear WalletConnect Data</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
