import { API_URL } from 'ApiUrl'
import axios from 'axios'
import useAuth from 'hooks/useAuth'
import Loader from 'loader/loader'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import "./kickback.scss"
const Kickback = () => {
    const [loader, setLoader] = useState(false)
    const [inviteCode, setInviteCode] = useState('')
    const { logout } = useAuth();
    const history = useHistory()
    let toastDesign = {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }
    const login = async () => {
        try {
            setLoader(true)
            const res = await axios.post(`${API_URL}/agents/challenge/validate/reference-code`, { referenceCode: inviteCode })
            // // console.log('res of the data', res?.data?.message)
            // if (res?.data?.message === 'Affiliate User exists') {
            //     history.push(`/landing/${inviteCode}`)
            // }
            toast.success('Reference Code valid', toastDesign)
            history.push(`/walletBanner?${inviteCode}`)
            setLoader(false)
        } catch (error) {
            toast.error(error?.response?.data?.message, toastDesign)
            setLoader(false)
            // // console.log('res of the data', error)
        }

    }
    useEffect(() => {
        logout()
        localStorage.clear()
    }, [])

    return (
        <>
            {loader && <Loader />}
            <section className='kickback'>
                <button type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="true" aria-haspopup="true" className="loginBtn common-btn py-2 px-3">
                    <p>Login</p>
                </button>
                <div class="dropdown-menu" id="dropdownMenuButtonInner" aria-labelledby="dropdownMenuButton">
                    <span className="">
                        <Link to='/adminLogin'  class="dropdown-item">Admin</Link>
                        <Link to='/affiliate' class="dropdown-item">Affiliate</Link>
                        <Link to='/agent' class="dropdown-item">Agent</Link>
                        </span>


                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-8 m-auto p-0'>
                            <div className='parent'>
                                <img src='\assests\biglogo.svg' alt='img' className='img-fluid' />
                                <div className='head'>
                                    <p>Your invite code</p>
                                    <input value={inviteCode} onChange={(e) => setInviteCode(e.target.value)} type="text" placeholder='Your invite code' />
                                    <a >
                                        <button onClick={login} className='common-btn'>Enter</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Kickback