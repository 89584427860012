import { API_URL } from 'ApiUrl'
import axios from 'axios'
import Loader from 'loader/loader'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import DataTableADetail from 'views/DataTable/DataTableADetail'
import './agents.scss'
import { useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
const Agents = () => {
  const statDataRedux = useSelector((state) => state.counter.value)
  const [loader, setLoader] = useState(false)
  let user = JSON.parse(localStorage.getItem("user"));
  const { history } = useHistory()
  let toastDesign = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }
  const [affliatesDetail, setAffiliateDetail] = useState()
  const [getAdminAffState, setgetAdminAffState] = useState()
  const [errors, setErrors] = useState(false)
  const [affiStatus, setAffiStatus] = useState(false)
  const [walletaddress, setWallet] = useState('')
  const [percentage, setPercentage] = useState('')
  const [referenceCode, setReferenceCode] = useState('')
  const [valid, setValid] = useState(false)
  const [Name, setName] = useState('')
  const [show, setShow] = useState(false);
  // // console.log('firstdfd', show)
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const getAgentsDetail = async () => {
    let tok = localStorage.getItem("accessToken");
    try {
      setLoader(true)
      const res = await axios.get(`${API_URL}/affiliates/${user?._id}`, {
        headers: {
          authorization: `Bearer ` + tok
        }
      })
      setLoader(false)
      // // console.log('first', res?.data?.data)
      setAffiliateDetail(res?.data?.data)
      // // console.log('res', res?.data?.data?.affiliates)
    } catch (error) {
      if (error.code == 401) {
        refreshToken()
      }
      setLoader(false)
      // // console.log('res error', error)
    }
  }
  const getAdminAffStats = async () => {
    let tok = localStorage.getItem("accessToken");
    try {
      setLoader(true)
      const res = await axios.get(`${API_URL}/purchases/revenue-stats`, {
        headers: {
          authorization: `Bearer ` + tok
        }
      })
      setLoader(false)
      // // console.log('first', res?.data?.data)
      setgetAdminAffState(res?.data?.data)
      // // console.log('res', res?.data?.data?.affiliates)
    } catch (error) {
      if (error.code == 401) {
        refreshToken()
      }
      setLoader(false)
      // // console.log('res error', error)
    }
  }
  const refreshToken = () => {


    let tok = localStorage.getItem("refreshToken");
    var data = ({
      refreshToken: tok,
    });
    var config = ''
    if (user?.role == 'admin') {
      config = {
        method: "post",
        url: `${API_URL}/auth/admins/refresh-token`,
        headers: {
          authorization: `Bearer ` + tok
        },
        data: data,
      };
    } else {
      config = {
        method: "post",
        url: `${API_URL}/auth/affiliate/refresh-token`,
        headers: {
          authorization: `Bearer ` + tok
        },
        data: data,
      };
    }

    axios(config)
      .then(function (response) {
        localStorage.setItem("accessToken", response?.data?.data?.accessToken);
        window?.location?.reload();
      })
      .catch(function (error) {
        if (user?.role == 'admin') {
          if (error.code == 401) {
            localStorage.clear();
            history.push("/");
          }
        } else {
          if (error.code == 401) {
            localStorage.clear();
            history.push("/");
          }
        }


        toast.error(error.response.data.message);
      });
  }
  const handlerOption = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    if (value.length < 11) {
      setReferenceCode(value)
      // // console.log(value.length, 'eee');

      if (value?.length >= 5 && value?.length < 11) {
        referenceCodeFunc()

      }
    } else {

    }

    // (e)
  }
  const clsedHandler = () => {
    setShow(false)
    setWallet('')
    setName('')
    setPercentage('')
    setErrors(false)
  }
  const referenceCodeFunc = async () => {
    try {
      setLoader(true)
      let tok = localStorage.getItem("accessToken");
      var data = ({
        referenceCode,
      });
      var config = {
        method: "post",
        url: `${API_URL}/affiliates/challenge/validate/reference-code`,
        headers: {
          authorization: `Bearer ` + tok
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          // toast.success('Reference Code valid', toastDesign)
          setLoader(false)
          setValid(true)
        })
        .catch(function (error) {
          setValid(false)
          if (user?.role == 'admin') {
            if (error.code == 401) {
              localStorage.clear();
              history.push("/adminlogin");
              history.push("/affiliate");
            }
          } else {
            if (error.code == 401) {
              localStorage.clear();
              history.push("/affiliate");
            }
          }
          // toast.error(error?.response?.data?.message, toastDesign)
          setLoader(false)
        });


    } catch (error) {

      // // console.log('res of the data', error)
    }

  }
  const createAffliates = async () => {


    var data;
    if (user?.role === 'affiliate') {
      data = {
        name: Name, walletAddress: walletaddress, cashbackPercentage: percentage
      }
    }

    setErrors(true)
    var rolee;
    if (user?.role === 'admin') {
      rolee = 'affiliates'
    } else if (user?.role === 'affiliate') {
      rolee = 'agents'
    }
    if (Name && walletaddress && percentage) {
      setShow(false)
      setLoader(true)
      let tok = localStorage.getItem("accessToken");
      var config = {
        method: "post",
        url: `${API_URL}/${rolee}`,
        data: data,
        headers: {
          authorization: `Bearer ` + tok
        }
      };
      axios(config)
        .then(function (response) {
          setLoader(false)
          toast.success(response?.data?.message, toastDesign);
          setShow(false)
          setWallet('')
          setName('')
          setPercentage('')
          setReferenceCode('')
          setErrors(false)
          setAffiStatus(!affiStatus)
        }).catch(function (error) {
          setLoader(false)
          if (error.code == 401) {
            refreshToken()
          }
          setShow(false)
          // // console.log('error', error)
          toast.error(error?.response?.data?.message, toastDesign)
          // // console.log(error);
          setShow(false)
          setWallet('')
          setName('')
          setPercentage('')
          setReferenceCode('')
          setErrors(false)
        });
    } else {
      // toast.error('ChainId is missing !')
    }


  }
  useEffect(() => {
    if (user?._id && user?.role != 'admin') {
      getAgentsDetail()
    }
    getAdminAffStats()
  }, [user?._id])
  return (
    <>
      {loader && <Loader />}
      <div className="content">
        <section className="main-agents">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-sm-12 p-0">
                <div className="topupperleft">
                  <div className="agentscard">
                    <div className="upperagentscard">
                      <div className="inneragentscard">
                        <div className="inneragentsimg colorone">
                          <img src="\assests\Extras\customer.svg" alt="agentsimg" className="agentsimg" />
                        </div>
                        <p className="inneragentscardtext">Total agents</p>
                      </div>
                      {user.role === 'affiliate' &&
                        <button onClick={handleShow} className="addagents">
                          <img src="\assests\home-assets\addaffiliatesimg.svg" alt="addagentsimg" className="addaffiliatesimg" />
                          Add
                        </button>
                      }
                    </div>
                    {user?.role === 'admin' ?
                      <p className="inneragentscardnumber">{statDataRedux?.agentsCount || 0}</p>
                      :
                      <p className="inneragentscardnumber">{affliatesDetail?.agentsCount || 0}</p>
                    }
                  </div>
                  <div className="agentscard">
                    <div className="upperagentscard">
                      <div className="inneragentscard">
                        <div className="inneragentsimg colortwo">
                          <img src="\assests\Extras\moneys.svg" alt="agentsimg" className="agentsimg" />
                        </div>
                        <p className="inneragentscardtext">Total Revenue</p>
                      </div>
                    </div>
                    {user?.role === 'admin' ?
                      <p className="inneragentscardnumber">${getAdminAffState?.totalAgentsRevenue || 0}</p>
                      :
                      <p className="inneragentscardnumber">${(user?.role === 'admin' && affliatesDetail?.stats[0]?.totalTomiRevenue) || (user?.role === 'affiliate' && affliatesDetail?.stats[0]?.totalAffiliateRevenue) || (user?.role === 'agent' && affliatesDetail?.stats[0]?.totalAgentRevenue) || 0}</p>
                    }
                  </div>
                </div>
                <div className="bottom-graph-main">
                  <h6>All Agents</h6>
                  <DataTableADetail affiStatus={affiStatus} setLoader={setLoader}/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className='abdullah'>
        <Modal className="addaffiliatemodal" show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{user?.role == 'admin' ? "Add Affiliate" : "Add Agent"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modalinputs">
              <div className="addinput">
                <p className="addinputpara">Name</p>
                <input onChange={(e) => setName(e.target.value)} value={Name} type="text" placeholder="Name" />
                {errors && (Name !== '' || <p className="errorMessage">Name is missing!</p>)}
              </div>
              <div className="addinput">
                <p className="addinputpara">Wallet address</p>
                <input onChange={(e) => setWallet(e.target.value)} value={walletaddress} type="text" placeholder="Wallet address" />
                {errors && (walletaddress !== '' || <p className="errorMessage errorMessage2">Walletaddress is missing !</p>)}
              </div>
              <div className="addinput">
                <p className="addinputpara">Percentage</p>
                <input value={percentage} onChange={(e) => { user?.role === 'affiliate' ? e.target.value <= user?.revenuePercentage && setPercentage(e.target.value) : setPercentage(e.target.value) }} type="number" placeholder="Amount of %" />
                {user?.role === 'affiliate' && <p className="errorMessage errorMessage2  text-secondary">Percentage must be less than equal to {user?.revenuePercentage}</p>}
                {errors && (percentage !== '' || <p className="errorMessage errorMessage2">Percentage is missing !</p>)}
              </div>

            </div>
            <div className="addaffiliatesbtn">
              <button className="white-btn" onClick={clsedHandler}>Cancel</button>
              <button disabled={referenceCode && !valid} onClick={createAffliates} className={(referenceCode && !valid) ? "common-btn btn-secondary cNPointer" : "common-btn"}> {user?.role == 'admin' ? "Add Affiliate" : "Add Agent"}</button>
            </div>
          </Modal.Body>
        </Modal>
      </section>

    </>

  )
}

export default Agents
