import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import InputEye from "../../assets/img/dashboardimg/Input-eye.svg";
import { toast } from "react-toastify";
import { API_URL } from "ApiUrl";
import axios from "axios";
import Loader from "loader/loader";

const CreateNewPassword = () => {
  const [currPass, setCurrPass] = useState()
  const [newPass, setNewPass] = useState()
  const [conNewPass, setConNewPass] = useState()
  const [errors, setErrors] = useState([]);
  const [loader, setLoader] = useState(false)
  const [inputType, setInputType] = useState('password')
  const [inputType2, setInputType2] = useState('password')
  const history = useHistory();
  let user = JSON.parse(localStorage.getItem("user"));
  // console.log('dafa', errors)
  let toastDesign = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }
  function validatePassword(password) {
    const errors = [];

    if (password.length < 8) {
      errors.push("Be at least 8 characters long");
    }

    if (!/[A-Z]/.test(password)) {
      errors.push("Have at least one uppercase");
    }

    if (!/[@$!%*?&]/.test(password)) {
      errors.push("Contain at least one special character (@$!%*?&)");
    }

    return errors;
  }
  function handlePasswordChange(event) {
    setNewPass(event.target.value);
    setErrors(validatePassword(event.target.value));
    const errors = validatePassword(event.target.value);
    setErrors(errors);
  }
  let ConfirFunc = async (event) => {
    event.preventDefault();
    if (errors?.length < 1) {
      var data = {
        currentPassword: currPass, newPassword: newPass, confirmPassword: conNewPass
      }

      // setErrors(true)
      var rolee;
      if (user?.role === 'affiliate') {
        rolee = 'affiliates'
      } else if (user?.role === 'agent') {
        rolee = 'agents'
      }
      if (currPass && newPass && conNewPass) {
        if (newPass === conNewPass) {


          // setShow(false)
          setLoader(true)
          let tok = localStorage.getItem("accessToken");
          var config = {
            method: "patch",
            url: `${API_URL}/${rolee}/password`,
            data: data,
            headers: {
              authorization: `Bearer ` + tok
            }
          };
          axios(config)
            .then(function (response) {
              setLoader(false)
              toast.success(response?.data?.message, toastDesign);
              history.push('/success')
            }).catch(function (error) {
              setLoader(false)
              if (error.code == 401) {
                // refreshToken()
              }
              // // console.log('error', error)
              toast.error(error?.response?.data?.message, toastDesign)
              // // console.log(error);
              setCurrPass('')
              setNewPass('')
              setConNewPass('')
              setErrors(false)
            });
        } else {
          toast.error('New Password is not matched with confirm password', toastDesign)
        }
      } else {
        toast.error('Fill All Fields !')
      }
    }

  }
  return (
    <>
      {loader && <Loader />}
      <section className="main-login main-login-inner">
        <form onSubmit={ConfirFunc} className="innerlogin">
          <img src="\logo.svg" alt="logo" className="logo" />
          <div className="newflow-box">
            <h4>Create New Password</h4>
            <p>
              Your new password must be different from previously used passwords.
            </p>
          </div>
          <div className="input-box-wrapper">
            <input
              required
              type={inputType}
              value={currPass}
              placeholder="Currunt Password"
              className="logininput"
              onChange={(e) => { setCurrPass(e.target.value) }}
            />
            <img src={InputEye} onClick={() => inputType === 'password' ? setInputType('text') : setInputType('password')} alt="InputEye " className="input-icon cPointer" />
          </div>
          <div className="input-box-wrapper">
            <input
              required
              value={newPass}
              type={inputType2}
              placeholder="New Password"
              className={errors?.length > 0 ? "logininput inputRed" : 'logininput'}
              onChange={handlePasswordChange}
            />
            <img src={InputEye} onClick={() => inputType2 === 'password' ? setInputType2('text') : setInputType2('password')} alt="InputEye " className="input-icon cPointer" />
          </div>
          {errors?.length > 0 &&
            <div className="passwoard-limits">
              <h4>Password Must:</h4>
              {errors?.map((item) => {
                return (
                  <p className="list-item-color-red">
                    <div className="passwoard-list-item-dot "></div>
                    {item}
                  </p>
                )
              })}

              {/* <p className="list-item-color-red">
            <div className="passwoard-list-item-dot"></div>
            Have at least one uppercase
          </p>
          <p className="list-item-color-green">
            <div className="passwoard-list-item-dot"></div>
            Contain at least one special character
          </p> */}
            </div>
          }
          <div className="input-box-wrapper">
            <input
              required
              value={conNewPass}
              type='text'
              placeholder="Confirm New Password"
              className="logininput"
              onChange={(e) => { setConNewPass(e.target.value) }}
            />

          </div>
          {((newPass && conNewPass) && (newPass !== conNewPass)) &&
            <>
              <p className="tewdf">Password not Matched !</p>
            </>
          }
          <button type="submit" className="common-btn">Confirm</button>
        </form>
      </section>
    </>
  );
};

export default CreateNewPassword;
