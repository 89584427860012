import { API_URL } from 'ApiUrl'
import axios from 'axios'
import Loader from 'loader/loader'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import DataTable from '../../views/DataTable/DataTable'
import './affiliates.scss'
import { useSelector } from 'react-redux'

const Affiliates = () => {
  const statDataRedux = useSelector((state) => state.counter.value)
  const [loader, setLoader] = useState(false)
  let user = JSON.parse(localStorage.getItem("user"));
  const {history}=useHistory()
  const [affliatesDetail, setAffiliateDetail] = useState()
const getAdminAffStats = async () => {
  let tok = localStorage.getItem("accessToken");
  try {
      setLoader(true)
      const res = await axios.get(`${API_URL}/purchases/revenue-stats`, {
          headers: {
              authorization: `Bearer ` + tok
          }
      })
      setLoader(false)
      // // console.log('first', res?.data?.data)
      setAffiliateDetail(res?.data?.data)
      // // console.log('res', res?.data?.data?.affiliates)
  } catch (error) {
      if (error.code == 401) {
          refreshToken()
      }
      setLoader(false)
      // // console.log('res error', error)
  }
}
const refreshToken = () => {


  let tok = localStorage.getItem("refreshToken");
  var data = ({
      refreshToken: tok,
  });
  var config = ''
  if (user?.role == 'admin') {
      config = {
          method: "post",
          url: `${API_URL}/auth/admins/refresh-token`,
          headers: {
              authorization: `Bearer ` + tok
          },
          data: data,
      };
  } else {
      config = {
          method: "post",
          url: `${API_URL}/auth/affiliate/refresh-token`,
          headers: {
              authorization: `Bearer ` + tok
          },
          data: data,
      };
  }

  axios(config)
      .then(function (response) {
          localStorage.setItem("accessToken", response?.data?.data?.accessToken);
          window?.location?.reload();
      })
      .catch(function (error) {
          if (user?.role == 'admin') {
              if (error.code == 401) {
                  localStorage.clear();
                  history.push("/");
              }
          } else {
              if (error.code == 401) {
                  localStorage.clear();
                  history.push("/");
              }
          }


          toast.error(error.response.data.message);
      });
}
useEffect(() => {
  getAdminAffStats()
}, [user?._id])
  return (
    <>
      {loader && <Loader />}
      <div className="content">
        <section className="main-affiliates">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-sm-12 p-0">
                <div className="topupperleft">
                  <div className="affiliatescard">
                    <div className="upperaffiliatescard">
                      <div className="inneraffiliatescard">
                        <div className="inneraffiliatesimg colorone">
                          <img src="\assests\home-assets\affiliateimg.svg" alt="affiliatesimg" className="affiliatesimg" />
                        </div>
                        <p className="inneraffiliatescardtext">Total Affiliates</p>
                      </div>
                    </div>
                    <p className="inneraffiliatescardnumber">{statDataRedux?.affiliatesCount}</p>
                  </div>
                  <div className="affiliatescard">
                    <div className="upperaffiliatescard">
                      <div className="inneraffiliatescard">
                        <div className="inneraffiliatesimg colortwo">
                          <img src="\assests\Extras\moneys.svg" alt="affiliatesimg" className="affiliatesimg" />
                        </div>
                        <p className="inneraffiliatescardtext">Total Revenue</p>
                      </div>
                    </div>
                    <p className="inneraffiliatescardnumber">${affliatesDetail?.totalAffiliatesRevenue}</p>
                  </div>
                </div>
                <div className="bottom-graph-main">
                  <h6>All Affiliates</h6>
                  <DataTable setLoader={setLoader} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default Affiliates
