import { API_URL } from 'ApiUrl'
import axios from 'axios'
import Loader from 'loader/loader'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import "./datatable.scss"
import copy from 'copy-to-clipboard';
import { toast } from "react-toastify";
import Demo from './demo';
const DataTable = ({ affiStatus, setLoader, hide, detailTable }) => {
  const [limit, setLimit] = useState(10)
  const [affliates, setAffliates] = useState([])
  const [search, setSearch] = useState('')
  const [copied, setCopied] = useState(false)
  const [pageNO, setPageNo] = useState(1)
  const history = useHistory()
  let user = JSON.parse(localStorage.getItem("user"));
  const getAffliates = async () => {
    let tok = localStorage.getItem("accessToken");
    try {
      setLoader(true)

      var res;
      // console.log("i mz",detailTable)
      if (user?.role == 'admin' && !detailTable) {
        res = await axios.get(`${API_URL}/customers?offset=${pageNO}&&limit=${limit}&&${search && `&&name=${search}`}`, {
          headers: {
            authorization: `Bearer ` + tok
          }
        })
      } else if (user?.role == 'agent') {
        res = await axios.get(`${API_URL}/customers?offset=${pageNO}&&limit=${limit}&&${search && `&&name=${search}`}&&agent=${user?._id}`, {
          headers: {
            authorization: `Bearer ` + tok
          }
        })
      } else if (user?.role == 'affiliate') {
        res = await axios.get(`${API_URL}/customers?offset=${pageNO}&&limit=${limit}&&${search && `&&name=${search}`}&&affiliate=${detailTable}`, {
          headers: {
            authorization: `Bearer ` + tok
          }
        })
      } else if (detailTable) {
        res = await axios.get(`${API_URL}/customers?offset=${pageNO}&&limit=${limit}&&${search && `&&name=${search}`}&&agent=${detailTable}`, {
          headers: {
            authorization: `Bearer ` + tok
          }
        })
      }




      setLoader(false)
      setAffliates(res?.data?.data)
      // // console.log('res', res?.data?.data?.affiliates)
    } catch (error) {
      if (error.code == 401) {
        refreshToken()
      }
      setLoader(false)
      // // console.log('res error', error)
    }
  }
  const copyText = () => {
    setCopied(true)
    copy(user?.referenceCode)
    setTimeout(() => {
      setCopied(false)
    }, 2000);
  }
  useEffect(() => {


    if (detailTable) {
      getAffliates()
    } else {
      getAffliates()
    }
  }, [affiStatus, limit, detailTable, pageNO])
  const refreshToken = () => {


    let tok = localStorage.getItem("refreshToken");
    var data = ({
      refreshToken: tok,
    });
    var config = ''
    if (user?.role == 'admin') {
      config = {
        method: "post",
        url: `${API_URL}/auth/admins/refresh-token`,
        headers: {
          authorization: `Bearer ` + tok
        },
        data: data,
      };
    } else {
      config = {
        method: "post",
        url: `${API_URL}/auth/affiliate/refresh-token`,
        headers: {
          authorization: `Bearer ` + tok
        },
        data: data,
      };
    }

    axios(config)
      .then(function (response) {
        localStorage.setItem("accessToken", response?.data?.data?.accessToken);
        window?.location?.reload();
      })
      .catch(function (error) {
        if (user?.role == 'admin') {
          if (error.code == 401) {
            localStorage.clear();
            history.push("/adminlogin");
          }
        } else {
          if (error.code == 401) {
            localStorage.clear();
            history.push("/affiliate");
          }
        }


        toast.error(error.response.data.message);
      });
  }
  return (
    <>
      {
        <div className='content'>
          <section className="datatable">
            <div class="table-responsivecolorbg">
              <div className="upper-content">
                <div className="left">
                  <span>show</span>
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {limit}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a onClick={() => setLimit(20)} class="dropdown-item">20</a>
                      <a onClick={() => setLimit(50)} class="dropdown-item">50</a>
                      <a onClick={() => setLimit(100)} class="dropdown-item">100</a>
                    </div>
                  </div>
                  <span>entries</span>
                </div>
                {user?.role === 'agent' ?
                  <div className="right">
                    <div className="option-field">
                      {/* <h6>Search:</h6> */}
                      <input className='px-3' readOnly value={`${user?.referenceCode}`} placeholder='Enter name...' type="search" />
                      <button onClick={copyText} className='btn ml-2 btn-outline-none border-0'> {copied ?
                        <p className='copiedText'>Copied</p>
                        :
                        // <p>Copy</p>
                        <img src="\assests\copy.svg" alt="copy" className="copy" />
                      }</button>
                    </div>
                  </div>
                  :

                  <div className="right d-none">
                    <div className="option-field">
                      <input className='px-3' onChange={(e) => setSearch(e.target.value)} placeholder='Enter name...' type="search" />
                      <button onClick={getAffliates} className='btn ml-2 btn-outline-none border-0'>Search</button>
                    </div>
                  </div>
                }
              </div>
              <div className="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <div className="parent-tag">
                          Created At <div className="filter-btn">
                            <img src="\assests\buttonsvgs\tableup.svg" alt="img" className='img-fluid' />
                            <img src="\assests\buttonsvgs\tabledown.svg" alt="img" className='img-fluid' />
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="parent-tag">
                          Wallet Address <div className="filter-btn">
                            <img src="\assests\buttonsvgs\tableup.svg" alt="img" className='img-fluid' />
                            <img src="\assests\buttonsvgs\tabledown.svg" alt="img" className='img-fluid' />
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="parent-tag">
                          Total CB <div className="filter-btn">
                            <img src="\assests\buttonsvgs\tableup.svg" alt="img" className='img-fluid' />
                            <img src="\assests\buttonsvgs\tabledown.svg" alt="img" className='img-fluid' />
                          </div>
                        </div>
                      </th>
                      {/* <th>
                      <div className="parent-tag">
                        Net. Revenue<div className="filter-btn">
                          <img src="\assests\buttonsvgs\tableup.svg" alt="img" className='img-fluid' />
                          <img src="\assests\buttonsvgs\tabledown.svg" alt="img" className='img-fluid' />
                        </div>
                      </div>
                    </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {affliates?.customers?.map((item) => {
                      return (

                        // <Link className='w-100 text-dark' to={`/admin/affiliatesdetails?${item?._id}`}>
                        <tr className='cPointer' onClick={() => history.push(`/admin/affiliatesdetails?customers||${item?._id}`)}>
                          <td>{item?.createdAt?.split('T')[0]?.replaceAll('-', '/')},  {item?.createdAt?.split('T')[1]?.slice(0, 5)}</td>
                          <td>{item?.walletAddress?.slice(0, 4)}...{item?.walletAddress?.slice(item?.walletAddress?.length - 4, item?.walletAddress?.length)}</td>
                          <td>{item?.cashbackPercentage} %</td>
                          {/* <td>${0}</td> */}

                        </tr>
                        // </Link>

                      )
                    })}

                  </tbody>
                </table>
                {affliates?.customers?.length === 0
                  &&
                  <div className="text-center errorMsg my-5">
                    <h4>No Data Found !</h4>
                  </div>
                }
              </div>
              {hide ||
                <div className="footer-content">
                  <div className="left-f">
                    <h6>Showing 1 to {limit} of {affliates?.count} entries</h6>
                  </div>
                  {/* <div className="right-f">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                      <li class="page-item"><a class="page-link active" href="#">1</a></li>
                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                      <li class="page-item"><a class="page-link" href="#">3</a></li>
                      <li class="page-item"><a class="page-link" href="#">4</a></li>
                      <li class="page-item"><a class="page-link" href="#">5</a></li>
                      <li class="page-item"><a class="page-link" href="#">Next</a></li>
                    </ul>
                  </nav>
                </div> */}
                  <Demo prop={affliates} setPageNo={setPageNo} />
                </div>
              }
            </div>
          </section>
        </div>}</>
  )
}

export default DataTable
